<template>
    <nav class="bg-white fixed w-full z-20 top-0 start-0 border-b border-gray-200">
        <div class="w-full h-10 p-4 flex flex-row justify-end bg-slate-200">
            <SwitchLanguage />
        </div>
        <div class="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
            <a href="https://www.go-platform.com/en/" class="flex items-center space-x-3 rtl:space-x-reverse">
                <img src="@/assets/logo.svg" class="h-8" alt="Flowbite Logo" />
                <!-- <span class="self-center text-2xl font-semibold whitespace-nowrap">GoPlatform</span> -->
            </a>
            <div class="flex md:order-2 space-x-3 md:space-x-6 rtl:space-x-reverse">
                <!-- <router-link to="/register" class="main-button"> {{ $t("Start for free") }} </router-link> -->
                <a
                    href="https://calendly.com/aouf-abdellah/20min"
                    target="_blank"
                    class="whitespace-nowrap font-semibold border border-CustomBlue rounded-md text-CustomBlue hover:bg-CustomBlue hover:text-white duration-300 px-4 py-2 flex items-center justify-center"
                    >Book a demo</a
                >
                <router-link
                    to="/login"
                    class="hero-button w-full px-4 h-12 bg-[#2196f3] shadow hover:opacity-[0.8] rounded text-base text-center flex justify-center whitespace-nowrap text-[18px] items-center text-white font-bold relative overflow-hidden"
                >
                    {{ $t("Log in") }}
                </router-link>

                <button
                    data-collapse-toggle="navbar-sticky"
                    type="button"
                    class="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200"
                    aria-controls="navbar-sticky"
                    aria-expanded="false"
                >
                    <span class="sr-only">Open main menu</span>
                    <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h15M1 7h15M1 13h15" />
                    </svg>
                </button>
            </div>
            <div class="items-center justify-between hidden w-full md:flex md:w-auto md:order-1" id="navbar-sticky">
                <ul class="flex flex-col p-4 md:p-0 mt-4 font-medium border border-gray-100 rounded-lg bg-gray-50 md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0 md:bg-white">
                    <li>
                        <div @mouseover="handleMouseOverA" @mouseleave="handleMouseLeaveA">
                            <a href="#" class="py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0" aria-current="page">Product</a>
                            <ProductMenu v-if="showComponentA" />
                        </div>
                    </li>

                    <li>
                        <a href="#" class="py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0">Pricing</a>
                    </li>
                    <li>
                        <div @mouseover="handleMouseOverB" @mouseleave="handleMouseLeaveB">
                            <a href="#" class="py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0">Ressources</a>
                            <RessourcesMenu v-if="showComponentB" />
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</template>

<script setup>
import { ref } from "vue";
import RessourcesMenu from "./RessourcesMenu.vue";
import ProductMenu from "./ProductMenu.vue";
import SwitchLanguage from "../SwitchLanguage.vue";

const showComponentA = ref(false);
const showComponentB = ref(false);

let timeoutIdA = null;
let timeoutIdB = null;

const handleMouseOverA = () => {
    clearTimeout(timeoutIdA);
    showComponentA.value = true;
};

const handleMouseLeaveA = () => {
    timeoutIdA = setTimeout(() => {
        showComponentA.value = false;
    }, 200); // 300ms delay
};

const handleMouseOverB = () => {
    clearTimeout(timeoutIdB);
    showComponentB.value = true;
};

const handleMouseLeaveB = () => {
    timeoutIdB = setTimeout(() => {
        showComponentB.value = false;
    }, 200); // 300ms delay
};
</script>
