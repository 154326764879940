<template>
    <section
        class="flex lg:flex-row justify-center items-center gap-4 lg:gap-0 px-6 pt-20 pb-8 lg:pt-24 lg:pb-0 min-h-screen"
        style="
            background-color: hsla(0, 0%, 100%, 1);
            background-image: radial-gradient(at 100% 99%, hsla(242, 100%, 70%, 1) 0px, transparent 50%), radial-gradient(at 0% 0%, hsla(206, 89%, 54%, 1) 0px, transparent 50%);
        "
    >
        <div class="flex flex-col justify-center items-center w-full lg:w-3/5">
            <h1 class="w-full text-center lg:text-center text-5xl lg:text-8xl font-bold text-[#0f172a]">
                Hiring made<br />
                <span class="typed-text my-10">{{ typeValue }}</span>
                <span class="blinking-cursor text-[#0f172a]">|</span>
                <span class="cursor" :class="{ typing: typeStatus }">&nbsp;</span>
            </h1>
            <p class="w-full text-center lg:text-center text-lg font-normal text-[#334155] my-10 lg:my-10">
                GO PLATFORM provides you with the best tools to screen your candidates so you can make better, faster and easier hiring decisions.
            </p>
            <div class="w-full flex flex-col lg:flex-row justify-between lg:justify-center items-center gap-5 lg:gap-10">
                <a href="/register" class="w-1/2 h-12 lg:w-44 flex items-center justify-center rounded-md px-2 py-3 text-lg font-semibold text-white bg-[#2196f3] hover:bg-[rgba(33,150,243,70%)]"
                    >Start for free</a
                >
                <a
                    class="w-1/2 h-12 lg:w-44 rounded-md border-2 border-white px-2 py-3 text-lg font-semibold text-center text-slate-700 bg-white flex justify-center items-center flex-row gap-2 hover:bg-transparent hover:text-white cursor-pointer"
                    @click="() => (this.showVideo = true)"
                >
                    Watch Video <font-awesome-icon :icon="['fas', 'circle-play']" class="w-7 h-7" />
                </a>
                <div v-if="this.showVideo" class="fixed top-0 left-0 w-full h-full bg-black bg-opacity-20 flex items-center justify-center z-20">
                    <div class="fixed top-0 left-0 -z-1 w-full h-full" @click="() => (this.showVideo = false)"></div>
                    <div class="w-2/3 h-2/3">
                        <iframe
                            class="w-[100%] h-[650px] mt-4"
                            width="560"
                            height="315"
                            :src="videoUrl"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen
                        ></iframe>
                    </div>
                </div>
            </div>
        </div>
        <!-- <img loading="lazy" decoding="async" src="../../assets/Images/woman_productTour.svg" alt="Go platform" class="w-full lg:w-2/5 lg:-mb-10 aspect-auto" /> -->
    </section>
</template>

<script>
export default {
    name: "HeroSection",
    components: {},
    data() {
        return {
            texts: ["effortless", "costless", "efficient"],
            typeValue: "",
            typeStatus: false,
            displayTextArray: ["effortless", "costless", "efficient"],
            typingSpeed: 100,
            erasingSpeed: 100,
            newTextDelay: 2000,
            displayTextArrayIndex: 0,
            charIndex: 0,
            showVideo: false,
            videoId: "P9pIfxgiPCk",
        };
    },
    computed: {
        videoUrl() {
            return `https://www.youtube.com/embed/${this.videoId}?autoplay=1&controls=0&modestbranding=1&rel=0`;
        },
    },
    methods: {
        typeText() {
            if (this.charIndex < this.displayTextArray[this.displayTextArrayIndex].length) {
                if (!this.typeStatus) this.typeStatus = true;
                this.typeValue += this.displayTextArray[this.displayTextArrayIndex].charAt(this.charIndex);
                this.charIndex += 1;
                setTimeout(this.typeText, this.typingSpeed);
            } else {
                this.typeStatus = false;
                setTimeout(this.eraseText, this.newTextDelay);
            }
        },
        eraseText() {
            if (this.charIndex > 0) {
                if (!this.typeStatus) this.typeStatus = true;
                this.typeValue = this.displayTextArray[this.displayTextArrayIndex].substring(0, this.charIndex - 1);
                this.charIndex -= 1;
                setTimeout(this.eraseText, this.erasingSpeed);
            } else {
                this.typeStatus = false;
                this.displayTextArrayIndex += 1;
                if (this.displayTextArrayIndex >= this.displayTextArray.length) this.displayTextArrayIndex = 0;
                setTimeout(this.typeText, this.typingSpeed + 1000);
            }
        },
    },
    mounted() {
        setTimeout(this.typeText, this.newTextDelay + 200);
    },
};
</script>

<style lang="scss" scoped>
// Cursor blinking CSS Starts...
.blinking-cursor {
    -webkit-animation: 1s blink step-end infinite;
    -moz-animation: 1s blink step-end infinite;
    -ms-animation: 1s blink step-end infinite;
    -o-animation: 1s blink step-end infinite;
    animation: 1s blink step-end infinite;
}
@keyframes blink {
    from,
    to {
        color: transparent;
    }
    50% {
        color: #0f172a;
    }
}
@-moz-keyframes blink {
    from,
    to {
        color: transparent;
    }
    50% {
        color: white;
    }
}
@-webkit-keyframes blink {
    from,
    to {
        color: transparent;
    }
    50% {
        color: white;
    }
}
@-ms-keyframes blink {
    from,
    to {
        color: transparent;
    }
    50% {
        color: white;
    }
}
@-o-keyframes blink {
    from,
    to {
        color: transparent;
    }
    50% {
        color: white;
    }
}
// Cursor blinking CSS Ends..
</style>
