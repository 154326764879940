<template>
    <section class="about-us">
        <div class="about-us-hero">
            <h1>{{ $t("How GO PLATFORM Helps") }}</h1>
            <h3>{{ $t("about us description") }}.</h3>
        </div>
        <AboutUsCards />
        <div class="about-us-button">
            <a href="#" class="cta-button">{{ $t("More About GO Platform") }}</a>
        </div>
    </section>
</template>

<script>
import AboutUsCards from "./AboutUsCards.vue";
export default {
    name: "AboutUs",
    components: {
        AboutUsCards,
    },
};
</script>

<style scoped>
.about-us {
    /*background-color: #f5f8ff;
    background-image: radial-gradient(at 100% 99%, hsla(242, 100%, 70%, 1) 0px, transparent 50%), radial-gradient(at 0% 0%, hsla(206, 89%, 54%, 1) 0px, transparent 50%);*/
    background: linear-gradient(to bottom, #3b6ff7 34%, #5652fb 62%, #7036fe 100%);
    padding: 50px 150px;
}

.about-us-hero {
    text-align: center;
    color: #ffffff;
}

.about-us-hero h1 {
    color: #ffffff;
    font-size: 36px;
    font-weight: 600;
    margin-bottom: 10px;
}

.about-us-hero h3 {
    color: #ffffff;
    font-weight: 400;
    font-size: 18px;
    padding: 0 150px;
}

.cta-button {
    display: inline-block;
    padding: 12px 40px;
    color: #334155;
    background-color: #fef5ff;
    font-size: 16px;
    font-weight: 600;
    border-radius: 6px;
    text-decoration: none;
    overflow: hidden;
}

@media (max-width: 1023px) {
    .about-us {
        padding: 30px 20px;
    }

    .about-us-hero h1 {
        font-size: 24px;
    }

    .about-us-hero h3 {
        font-size: 12px;
        padding: 0 50px;
    }

    .cta-button {
        font-size: 12px;
        padding: 8px 40px;
    }
}
</style>
