<template>
    <section class="tools">
        <div class="tools-hero">
            <h1>{{ $t("All The Tools That You Need") }}</h1>
            <h4>{{ $t("Skill Assessment and Talent resourcing in a single solution.") }}</h4>
        </div>
        <div class="tools-sections">
            <ToolSection
                :buttonIconSrc="Press"
                buttonIconWidth="15px"
                buttonIconHeight="auto"
                buttonText="Anti Cheat System"
                :iconList="[
                    'Prevent cheating and ensure fair, reliable assessment results.',
                    'Identif candidates with actual skills, not just the ability to game the system.',
                    'Minimize the impact of dishonest behaviors, promoting a more objective evaluation process.',
                    'Trust the validity of your assessment data to make well-informed hiring decisions.',
                    'Implement a system that discourages cheating attempts and maintains high standards for all assessments.',
                    'Leverages AI and machine learning to identify suspicious activities',
                ]"
                :imageSrc="require('@/assets/Images/Anti-Cheat-Monitor.png')"
                imageAlt="Anti-Cheat Monitor"
                imageClass="anti-cheat-monitor"
                :contentFirst="true"
                imageWidth="300px"
                imageHeight="auto"
            >
                Roll out Cheaters in a <span class="highlight">matter of one click</span>
            </ToolSection>

            <ToolSection
                :buttonIconSrc="Bell"
                buttonIconWidth="15px"
                buttonIconHeight="auto"
                buttonText="Automation"
                description="Simplify and accelerate your hiring process with GO PLATFORM's streamlined recruitment tools. our solution automates key steps as screening, reduces administrative overhead, and ensures a seamless, efficient experience for your team and potential candidates."
                :iconList="[
                    'Accelerate your recruitment cycle with automated workflows.',
                    'Reduce the manual work and improve time-to-hire.',
                    'Create a positive and seamless experience for job applicants.',
                    'Manage all candidates in one platform, improving organization and data accessibility.',
                    'Make informed decisions based on real-time recruitment analytics and reports.',
                ]"
                :imageSrc="require('@/assets/Images/Automated-Workflow.png')"
                imageAlt="Automated Workflow"
                imageClass="automated-workflow"
                :contentFirst="false"
                imageWidth="400px"
                imageHeight="auto"
            >
                <span class="highlight">Save time</span> with automated that drive actions
            </ToolSection>
            <ToolSection
                :buttonIconSrc="User"
                buttonIconWidth="15px"
                buttonIconHeight="auto"
                buttonText="Skills-Based Hiring"
                title="Frictionless evidence Assessments and Review"
                description="Transform your hiring strategy with GO PLATFORM's skills-based hiring approach. Focus on assessing candidates' actual competencies and potential, rather than relying on traditional criteria. Discover the best talent fit for each role, ensuring that new hires bring the specific skills needed to succeed in your organization."
                :iconList="[
                    'Focus on candidates with demonstrated capabilities',
                    'Match candidates to roles based on proven skills and competencies, not just resumes.',
                    'Minimize the risk of bad hires by assessing real-world skills and abilities.',
                    'Broaden your talent pool by evaluating candidates based on skills, regardless of their background.',
                    'Recruit candidates with the most relevant skills for today and for the future business needs.',
                ]"
                :imageSrc="require('@/assets/Images/Competencies.png')"
                imageAlt="Competencies"
                imageClass="competencies"
                :contentFirst="true"
                imageWidth="450px"
                imageHeight="auto"
            >
                <span class="highlight">Frictionless evidence</span> Assessments and Review
            </ToolSection>
        </div>
    </section>
</template>

<script>
import ToolSection from "./ToolSection.vue";
import Press from "@/assets/press.png";
import Bell from "@/assets/bell.png";
import User from "@/assets/user.png";
export default {
    name: "ToolsComponent",
    components: {
        ToolSection,
    },
    data() {
        return {
            Press: Press,
            Bell: Bell,
            User: User,
        };
    },
};
</script>

<style scoped>
.tools {
    background-color: #f5f8ff;
    width: 100%;
}

.tools-hero h1 {
    font-size: 36px;
    font-weight: 600;
    color: #334155;
    text-align: center;
}

.tools-hero h4 {
    color: #334155;
    font-size: 18px;
    font-weight: 400;
    text-align: center;
    margin-top: 15px;
}

.tools-sections {
    display: flex;
    flex-direction: column;
}

.highlight {
    color: #2160fd;
    font-weight: 700;
}

@media (max-width: 1023px) {
    .tools {
        padding: 30px 20px;
    }

    .tools-hero h1 {
        font-size: 24px;
    }

    .tools-hero h4 {
        font-size: 12px;
    }
}
</style>
